import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../../components/layouts'

import {
	CardForm,
	ThankYouMsj,
} from '../../../components/ui'

import {
	clearErrors,
	submitOrgRequest
} from '../../../factory'

import theme from '../../../theme/theme'

const {
	oneTimeDonation,
} = theme.sections

// TODO: Specific error for page
class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
		}
	}
	render() {
		return (
			<Layout>
				<Section bg={oneTimeDonation.bg} paddingTop={'8em'} paddingBottom={'3em'}>
					<Container maxWidth={'840px'}>
						<Row>
							<Column>
								<CardForm>
									<ThankYouMsj
										title={"You are awesome!"}
										description1={"Thanks for your information - we will be in touch soon"}
										description2={"Ready to turn pocket change into global change?"}
										image={theme.sections.donateNow.forms.thanks.image}
										homeBtn={{
											text: "Learn More",
											link: "/nonprofits",
										}}
									/>
								</CardForm>
							</Column>
						</Row>
					</Container>
				</Section>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	error: PropTypes.any,
	pending: PropTypes.any,
	clearErrors: PropTypes.func,
	submitOrgRequest: PropTypes.func,
	success: PropTypes.bool,
}

const mapStateToProps = (state) => {
	const { utilState } = state
	return {
		error: utilState.error,
		pending: utilState.pending,
		success: utilState.success
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clearErrors: () => dispatch(clearErrors()),
		submitOrgRequest: (data, callBack) => dispatch(submitOrgRequest(data, callBack))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(IndexPage)
